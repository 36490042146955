import React from "react";
import { graphql } from "gatsby";
import Layout from "components/Layout";

const Component = ({ data: { markdownRemark: imprint } }) => (
  <Layout>
    <div dangerouslySetInnerHTML={{ __html: imprint.html }} />
  </Layout>
);

export const query = graphql`
  query Imprint {
    markdownRemark(frontmatter: { title: { eq: "imprint" } }) {
      id
      html
    }
  }
`;

export default Component;
